import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  RadioGroup,
  HStack,
  Radio,
  Flex,
  Stack,
  Box,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  Text,
  Grid,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

import AutoSelect from '../../patients/components/autoselect';
import Planning from '../../patients/components/Planning';
import { Spinner } from '@chakra-ui/react';
import AutoSelectPEC from './Selectkine';
import { create } from 'domain';
import apiClient from 'views/admin/appclient';
type NewPECModalProps = {
  isOpen: boolean;
  onClose: () => void;
  refreshData: () => void;
  pecData?: any;
};
interface Patient {
  _id: string; 
}

interface DaysOfWeek {
  [key: string]: boolean;
}
interface VisitData {
  _id?: string;
  patient: string;
  startDate: string;
  endDate: string;
  schedule: { startHour: string; endHour: string; daysOfWeek: string }[];
}
const NewPECModal: React.FC<NewPECModalProps> = ({ isOpen, onClose, refreshData, pecData }) => {
  const [patients, setPatients] = useState([]);
  const [medTeamMembers, setMedTeamMembers] = useState([]);
  const [kineTeamMembers, setKineTeamMembers] = useState([]);
  const [treatmentsRecourse, setTreatmentsRecourse] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedMed, setSelectedMed] = useState('');
  const [selectedKine, setSelectedKine] = useState('');
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [firstSessionDate, setFirstSessionDate] = useState('');
  const [assuranceNumber, setAssuranceNumber] = useState('');
  const [patalogie, setPatalogie] = useState('');
  const [nombreDeScDemande, setNombreDeScDemande] = useState(pecData?.NbSc?.toString());
  const [paymentType, setPaymentType] = useState('');
  const [isCnamPayment, setIsCnamPayment] = useState(pecData?.paymentType === "CNAM");
  const [isPayout, setIsPayout] = useState(pecData?.paymentType === "Payout");
    const [pricePerVisit , setpricePerVisit] = useState('');
  const [nbScAccorde, setNbScAccorde] = useState<string>('12');
  const [nbScParSemaine, setNbScParSemaine] = useState<string>('3');
  const [dateDeFinPec ,setdateDeFinPec] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [PECExist, setPECExist]= useState(false);
  const [dateFin, setDateFin] = useState<string>('');
  const [moneyadded , setmoneyadded] = useState<string>('');
  const [amountToPay, setAmountToPay] = useState<string>('');
  const [customDays, setCustomDays] = useState<DaysOfWeek>({});
  const [planning, setPlanning] = useState<string[]>([]);
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');
  const token = localStorage.getItem('token');
  const [startSamedi , setStartSamedi]= useState('');
  const [endSamedi , setEndSamedi]= useState('');
  const [editedData , setEditedData] = useState(pecData);
  const [waiting, setWaiting] = useState(false);
  const data =  pecData?._id ? true : false ;
  const [isChanged , setIsChanged] = useState(false);
  useEffect(() => {
    if (pecData?.paymentType === "CNAM") {
      setIsCnamPayment(true);
      setIsPayout(false);
    } else if (pecData?.paymentType === "Payout") {
      setIsCnamPayment(false);
      setIsPayout(true);
    }
  }, [pecData]);
  
  const handlePaymentTypeChange = (value: string) => {
    setPaymentType(value);
    if (value === "CNAM") {
      setIsCnamPayment(true);
      setIsPayout(false);
    } else {
      setIsCnamPayment(false);
      setIsPayout(true);
    }
  };
  const fetchTeam = async ()  => {

    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      apiClient.get('/api/patient/optionlist', config).then((response: { data: React.SetStateAction<any[]>; }) => {
        setPatients(response.data);

      });
      apiClient.get('/api/resources', config).then((response) => {
        const medTeamMembers = response.data.medecins.map((medecin : any) => ({
          value: medecin._id.toString(), 
          label: medecin.nom
        }));
        const kineTeamMembers = response.data.kinés.map((kine : any ) => ({
          value: kine._id.toString(),
          label: kine.firstName
        }));
        const Treatment = response.data.treatments?.map((treatment : any ) => ({
          value: treatment._id.toString(),
          label: treatment.name
        }));
      setTreatmentsRecourse(Treatment)
        setMedTeamMembers(medTeamMembers);
        setKineTeamMembers(kineTeamMembers);
      });

    }
  }

  useEffect(() => {
    let calculatedAmountToPay = null;

    if (isPayout && pricePerVisit && nombreDeScDemande) {
      calculatedAmountToPay = parseInt(pricePerVisit) * parseInt(nombreDeScDemande);
      setAmountToPay(calculatedAmountToPay.toString());
    } else if (isCnamPayment && moneyadded && nbScAccorde) {
      calculatedAmountToPay = parseInt(moneyadded) * parseInt(nbScAccorde);
      setAmountToPay(calculatedAmountToPay.toString());
    } else {
      return;
    }
  }, [isPayout, isCnamPayment, moneyadded, nbScAccorde, pricePerVisit, nombreDeScDemande]);
    const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const formData = new FormData();



  const [daysOfWeek, setDaysOfWeek] = useState<DaysOfWeek>({

    Lundi: false,
    Mardi: false,
    Mercredi: false,
    Jeudi: false,
    Vendredi: false,
    Samedi: false,
  });
  const [conflictMessage, setConflictMessage] = useState('');
  const [programmeData, setProgrammeData] = useState([]);

  const fetchKineSchedule = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiClient.get(`/api/kine/${selectedKine}/`, config);
      const fetchedProgrammeData = response.data.visits || [];
      setProgrammeData(fetchedProgrammeData);
    } catch (error) {
      console.error('Error fetching kiné schedule:', error);
    }
  };


  useEffect(() => {

    const checkScheduleAndNotify = async () => {
      if (customDays && firstSessionDate && dateFin && nbScParSemaine && nombreDeScDemande && selectedPatient && startHour && endHour && selectedKine && programmeData)
   {   const selectedDays = Object.keys(customDays).filter((day) => customDays[day]);

      const exactDates = calculateExactDates(
        new Date(firstSessionDate),
        selectedDays,
        parseInt(nbScParSemaine),
        parseInt(nombreDeScDemande)
      );

      const visitData = {
        patient: selectedPatient,
        startDate: new Date(firstSessionDate),
        endDate: new Date(dateFin),
        schedule: exactDates.map((exactDate) => ({
          startHour,
          endHour,
          daysOfWeek: exactDate,
        })),
      };

      try {
        if (programmeData.length > 0) {
          const hasConflict = programmeData.some((visit) => {
            const visitStartHours = visit.schedule.map((item :any) => item.startHour).flat();
            const visitEndHours = visit.schedule.map((item :any) => item.endHour).flat();
            const visitDaysOfWeek = visit.schedule.map((item :any) => item.daysOfWeek).flat();

            const newSessionStartHour = visitData.schedule.map((item) => item.startHour).flat();
            const newSessionEndHour = visitData.schedule.map((item) => item.endHour).flat();
            const newSessionDaysOfWeek = visitData.schedule.map((item) => item.daysOfWeek).flat();

            return (
              newSessionDaysOfWeek.some((day) => visitDaysOfWeek.includes(day)) &&
              (newSessionStartHour.some((startHour) => visitStartHours.includes(startHour)) ||
                newSessionEndHour.some((startHour) => visitEndHours.includes(startHour)))
            );
          });
          if (hasConflict) {
            setConflictMessage("Kiné n'est pas disponible.");
          } else {
            setConflictMessage('kiné disponible');
          }
        } else {
          setConflictMessage('pas de programme pour ce kine');
        }
      } catch (error) {
        console.error('Error checking kiné schedule:', error);
      }
    }

    };
    checkScheduleAndNotify();
  }, [customDays, firstSessionDate, dateFin, nbScParSemaine, nombreDeScDemande, selectedPatient, startHour, endHour, selectedKine, programmeData]);
  useEffect(() => {
    const fetchKineAndCheckSchedule = async () => {
      if (selectedKine) {
        await fetchKineSchedule();

      }
    };

    fetchKineAndCheckSchedule();
  }, [selectedKine]);

  function getDayIndex(dayName: string) {
    return { 'Lundi': 1, 'Mardi': 2, 'Mercredi': 3, 'Jeudi': 4, 'Vendredi': 5, 'Samedi': 6 }[dayName];
  }
  const calculateExactDates = useCallback((
    firstSessionDate: Date,
    selectedDays: string[],
    numScParSemaine: number,
    totalSessions: number
  ) => {
    const exactDates = [];
    const selectedDayIndices = selectedDays.map(day => getDayIndex(day));
    let currentDate = new Date(firstSessionDate);
  
    for (let i = 0; i < totalSessions; ) {
      const currentDayIndex = currentDate.getDay(); 
  
      if (selectedDayIndices.includes(currentDayIndex)) {
        const selectedDay = selectedDays[selectedDayIndices.indexOf(currentDayIndex)];
        exactDates.push(`${capitalizeFirstLetter1(selectedDay)} ${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`);
        i++;
      }
        currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return exactDates;
  }, []);
  
  
  const calculateSelectedDays = (firstSessionDate: string, nbScParSemaine: string) => {
    if (firstSessionDate && nbScParSemaine) {
      const startDate = new Date(firstSessionDate);
      const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
      const selectedDaysAuto = [];
      let currentDayIndex = startDate.getDay();
      const step = Math.floor(7 / parseInt(nbScParSemaine)); // Calculate the step based on sessions per week
  
      for (let i = 0; i < parseInt(nbScParSemaine); i++) {
        selectedDaysAuto.push(dayNames[currentDayIndex]);
        currentDayIndex = (currentDayIndex + step) % 7;
      }
  
      return selectedDaysAuto;
    }
    return [];
  };
  
  

  function capitalizeFirstLetter1(string: string | undefined) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return "";
  }

  
  
  
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleToggle = () => {
    setPECExist((prevValue) => !prevValue);
  };
  const amountToPayNumber = parseFloat(amountToPay);
  useEffect(() => {
    fetchTeam() ;
  }, [token]);
  const [fileLink, setFileLink] = useState('');
const [upload, setUpload] = useState(false);
const [fileName, setFileName] = useState('');
  const handleFileChange = async (e:any) => {
    setUpload(true)
    const file = e.target.files && e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        const { data } = await apiClient.post('/upload', formData, config);
        setFileLink(data.cloudinaryUrl);
        setFileName(file.name);
      } catch (error) {

      }finally {
        setUpload(false);
      }
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };
  const  selectedDay = customDays['Samedi'];
  
  const updateCustomDays = useCallback(() => {
    if (firstSessionDate && nbScParSemaine&& !data  ) {
      const selectedDaysAuto = calculateSelectedDays(firstSessionDate, nbScParSemaine);
      if (selectedDaysAuto.length > 0) {
        const customDaysCopy = { ...customDays };
        Object.keys(customDaysCopy).forEach((day) => {
          customDaysCopy[day] = false;
        });
        selectedDaysAuto.forEach((day) => {
          customDaysCopy[day] = true;
        });
  
        setCustomDays(customDaysCopy);
      }
    }
  }, [firstSessionDate, nbScParSemaine , nombreDeScDemande]);
  
  useEffect(() => {
    updateCustomDays();
  }, [updateCustomDays]);
  
  const handlePlanningCalculationEdited = useCallback(() => {
    if (firstSessionDate && nbScParSemaine && nombreDeScDemande) {
      const selectedDays = Object.keys(customDays).filter((day) => customDays[day]);
      if (selectedDays.length === 0) {
        console.log('No days selected, skipping planning calculation.');
        return;
      }
      const calculatedExactDates = calculateExactDates(
        new Date(firstSessionDate),
        selectedDays,
        parseInt(nbScParSemaine),
        parseInt(nombreDeScDemande)
      );
      setPlanning(calculatedExactDates);

      if (calculatedExactDates.length > 0) {
        const lastDate = calculatedExactDates[calculatedExactDates.length - 1];
        const parts = lastDate.split(' ');
        const dayMonthYear = parts[1].split('/');
        const dd = dayMonthYear[0].padStart(2, '0');
        const MM = dayMonthYear[1].padStart(2, '0');
        const yyyy = dayMonthYear[2];
        const formattedDateValue = `${yyyy}-${MM}-${dd}`;
        setDateFin(formattedDateValue);
      }
    } 
  }, [ customDays, firstSessionDate, nbScParSemaine, nombreDeScDemande, isChanged]);
  

  useEffect(() => {
    const updateScheduledDays = async () => {
      if (data) {
        if (pecData && pecData.patientVisitsDetails) {       
          const allScheduledDays = new Set<string>();
          
          pecData.patientVisitsDetails.forEach((visit: { schedule: any[]; }) => {
            visit.schedule.forEach((entry) => {
              entry.daysOfWeek.forEach((day: unknown) => {
                if (typeof day === 'string') {
                  allScheduledDays.add(day);
                }
              });
            });
          });
    
          const scheduledDaysArray = Array.from(allScheduledDays);
          const daysOfWeekArray = scheduledDaysArray.map(dateStr => dateStr.split(' ')[0]);
          const newCustomDays: DaysOfWeek = {};
          daysOfWeekArray.forEach(day => {
            newCustomDays[day] = true; 
          });
          const selectedDays = Object.keys(newCustomDays).filter((day) => newCustomDays[day]);
          selectedDays.forEach(day => {
          newCustomDays[day] = true;
      });
    setCustomDays(newCustomDays);
          setPlanning(scheduledDaysArray);
        }
      }
    };
  
    updateScheduledDays();
  }, [isOpen]);
  useEffect
(() => {
  
    handlePlanningCalculationEdited();
},[handlePlanningCalculationEdited])
  const [showDateInputs, setShowDateInputs] = useState(false);
  useEffect(() => {
    if (selectedDay ) {
      setShowDateInputs(true);
    } else {
      setShowDateInputs(false);
    }
  }, [selectedDay]);

  const handleClose = () => {
    resetState();
    onClose();
  };
  useEffect(() => {
    if (isOpen && data) {
      try {
        setpricePerVisit(pecData.pricePerVisit || '');

        setDateFin(pecData.dateFin || '');

        if (pecData.patient && Array.isArray(pecData.patient)) {
          setSelectedPatient(pecData.patient[0] || '');
          setWaiting(pecData.patient[0]?.isInWaitingList || false);
        } else {
          setSelectedPatient('');
          setWaiting(false);
        }

        setSelectedMed(pecData.doctor?.[0]?._id || '');
        setSelectedKine(pecData.kine?.[0]?._id || '');
        setSelectedTreatment(pecData.treatment || '');

     
    setStartHour(pecData.patientVisitsDetails[0]?.schedule[0]?.startHour || '');
    setEndHour(pecData.patientVisitsDetails[0]?.schedule[0]?.endHour || '');
        setAssuranceNumber(pecData.codePECCNAM || '');
        setPatalogie(pecData.patalogie || '');
        setmoneyadded(pecData.moneyadded || '');
        setAmountToPay(pecData.amountToPay || '');
        setPaymentType(pecData.paymentType || '');
        setNbScAccorde(pecData.NbScCnam || '');
        setNbScParSemaine(pecData.NbScperweek || '');
        setNombreDeScDemande(pecData.NbSc || '');
        setdateDeFinPec(pecData.dateDeFinPec || '');
        setDateDebut(pecData.dateFactureCNAM || '');
        setPECExist(pecData.PECExist || false);

    const formattedDate = pecData.datefirstVisit
        ? new Date(pecData.datefirstVisit).toISOString().split('T')[0]
        : '';
      setFirstSessionDate(formattedDate);




      } catch (error) {
        console.error("Error updating modal state:", error);
      }
    }
  }, [data, isOpen]);

  const handleSubmit = () => {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      setIsLoading(true);
      const selectedDays = Object.keys(customDays).filter((day) => customDays[day]);

      const exactDates = calculateExactDates(
        new Date(firstSessionDate),
        selectedDays,
        parseInt(nbScParSemaine),
        parseInt(nombreDeScDemande)
      );

      const visitDataWithoutId: Omit<VisitData, '_id'> = {
        patient: (selectedPatient as any)._id || selectedPatient,        
        startDate: firstSessionDate,
        endDate: dateFin,
        schedule: exactDates.map((exactDate) => ({
          startHour: startHour,
          endHour: endHour,
          daysOfWeek: exactDate,
        })),
      };
      const visitData: VisitData = pecData?.visits?.[0] 
        ? { ...visitDataWithoutId, _id: pecData.visits[0] }
        : visitDataWithoutId;

      formData.append('kine', selectedKine);

      formData.append('treatment', selectedTreatment);
      formData.append('datefirstVisit', firstSessionDate);
      formData.append('codePECCNAM', assuranceNumber);
      formData.append('patalogie', patalogie);
      formData.append('NbSc', nombreDeScDemande);
      formData.append('NbScperweek', nbScParSemaine);
      formData.append('paymentType', paymentType);
      formData.append('PECExist', PECExist.toString());
      formData.append('moneyadded', moneyadded);
      if (visitData._id) {
        formData.append('visits[_id]', visitData._id);
      }      formData.append('visits[patient]', visitData.patient);
      formData.append('visits[startDate]', visitData.startDate);
      formData.append('visits[endDate]', visitData.endDate);
      visitData.schedule.forEach((scheduleItem, index) => {
        if (scheduleItem.daysOfWeek.includes('Samedi')) {
            formData.append(`visits[schedule][${index}][startHour]`, startSamedi);
            formData.append(`visits[schedule][${index}][endHour]`, endSamedi);
            formData.append(`visits[schedule][${index}][daysOfWeek]`, scheduleItem.daysOfWeek);
        } else {
            formData.append(`visits[schedule][${index}][startHour]`, scheduleItem.startHour);
            formData.append(`visits[schedule][${index}][endHour]`, scheduleItem.endHour);
            formData.append(`visits[schedule][${index}][daysOfWeek]`, scheduleItem.daysOfWeek);
        }
    });

      formData.append('NbScCnam', nbScAccorde);
      formData.append('dateDeFinPec', dateDeFinPec);
      formData.append('dateLastVisit' , dateFin)
      formData.append('dateFactureCNAM', dateDebut);
      formData.append('pricePerVisit', pricePerVisit);
      formData.append('amountToPay', amountToPay);
      formData.append('isInWaitingList', waiting.toString());
      formData.append('file', fileLink)
      // console.log(formData)
      const method = data ? 'patch' : 'post';
      const endpoint = data ? `/api/charges/update` : '/api/charges';
      formData.append('doctor', selectedMed);

      if (data) {
        formData.append('_id', pecData._id);
        formData.append('patient', pecData.patient[0]._id);


      }
      else 
      {
        formData.append('patient', selectedPatient);


      }
      apiClient[method](endpoint, formData, config)
      .then((pecResponse: { data: any }) => {
        toast({
          title: "Succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refreshData();
        onClose();
      })
      .catch((error: any) => {
        console.error('Error creating or updating PEC:', error);
        toast({
          title: "Save failed",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
};

  const resetState = () => {
    setSelectedPatient(null);
    setSelectedMed(null);
    setSelectedKine(null);
    setSelectedTreatment(null);
    setAssuranceNumber('');
    setPatalogie('');
    setNombreDeScDemande('');
    setPaymentType('');
    setIsCnamPayment(false);
    setIsPayout(true);
    setpricePerVisit('');
    setNbScAccorde('12');
    setNbScParSemaine('3');
    setdateDeFinPec('');
    setConflictMessage('');
    setDateDebut('');
    setPECExist(false);
    setDateFin('');
    setmoneyadded('');
    setAmountToPay('');
    setStartHour('');
    setEndHour('');
    setStartSamedi('');
    setEndSamedi('');
    setFirstSessionDate('');
    setdateDeFinPec('');
    setPlanning([]);
    setCustomDays({})
    setIsChanged(false);
  };
  

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>

      <ModalOverlay />
      <ModalContent maxW={{ base: '100%', sm: '100%', md: '100%', lg: '80%' }}>
      <ModalHeader color="blue.500" fontSize={"32px"}>{data ? "Modifier prise en charge" : "Ajouter prise en charge " }</ModalHeader>
      <ModalCloseButton />
        <ModalBody>
          <Grid gap={12}>
            <Box   display="flex"
              maxWidth="100%"
              overflow="auto"
              borderWidth="1px"
              boxShadow="5px 14px 10px 0px  rgba(128, 128, 128, 0.5)"
              padding="4"
              borderRadius="xl" >
              <Stack spacing={0} flex={8} mr={5} >
                <Box  fontSize={"18"} flex={1} mr={2} >
                  <AutoSelect
                    label="Patient"
                    defaultValue={patients?.find((k) => k.value === pecData?.patient?.[0]?._id || null)}

                    options={patients}
                    onChange={(selectedOption) => {
                      setSelectedPatient(selectedOption.value);
                    }}

                  />

                <Flex mt={4} alignItems="center">
                  <Box fontSize={"18"} flex={1} mr={2}>
                    <AutoSelectPEC
                      label="Médecin"
                      defaultValue={medTeamMembers?.find((k) => k.value === pecData?.doctor?.[0]?._id || null)}

                      options={medTeamMembers}
                  onChange={(selectedOption) => {
                    setSelectedMed(selectedOption?.value || '');
                  }}
                  apiEndpoint="/api/medecin"
                  apiField="nom"
                  apiMethod="post"
                    />
                  </Box>
                  <Box  fontSize={"18"} flex={1}>

                  <AutoSelectPEC
                  label="Kiné"
                  options={kineTeamMembers}
                  defaultValue={kineTeamMembers?.find((k) => k.value === pecData?.kine?.[0]._id || null)}

                  onChange={(selectedOption) => {
                    setSelectedKine(selectedOption?.value || '');
                  }}
                  apiEndpoint="/api/kine"
                  apiField='firstName'
                  apiMethod="post"
                />
                  </Box>
                </Flex>
        <Flex mt={4}>

                <FormControl>
                <FormLabel fontSize={18}>Date premier seance</FormLabel>
                <Input
                  placeholder="Date permiere seance"
                  type="date"
                  value={firstSessionDate}
                  onChange={(e) => setFirstSessionDate(e.target.value)}
                  bg="white"

                />
              </FormControl>

                <FormControl>
              <FormLabel fontSize={18}>Date de fin de traitement</FormLabel>
                  <Input
                    placeholder="Date de fin"
                    type="date"
                    value={dateFin}
                    onChange={(e) => setDateFin(e.target.value)}
                    bg={"white"}
                  />

                </FormControl>
                </Flex>
                <FormControl mt={4}>

                <AutoSelectPEC
                label="Traitment"
                defaultValue={treatmentsRecourse?.find((k) => k.value === pecData?.treatment||null)}

                options={treatmentsRecourse}
                onChange={(selectedOption) => {
                  setSelectedTreatment(selectedOption?.value || '');
                }}
                apiEndpoint="/api/treatment"
                apiField="name"
                apiMethod="patch"
              />
                      </FormControl>

                <Box>
                <Flex alignItems="center" flex={2} mt={4}>
               <FormControl mb={4}>
  <FormLabel fontSize={18}>Nombre de séances demandées</FormLabel>
  <NumberInput
    value={nombreDeScDemande}
    bg="white"
    onChange={(valueString) => {
      const parsedValue = parseInt(valueString, 10);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        setNombreDeScDemande(parsedValue.toString());
      }
    }}
    step={1}
    min={0}
  >
    <NumberInputField />
    <NumberInputStepper bg="gray.200">
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
</FormControl>

<FormControl mb={4} ml={2} >
  <FormLabel fontSize={18}>Nombre de séances par semaine</FormLabel>
  <NumberInput
    value={nbScParSemaine}
    bg="white"
    onChange={(valueString) => {
      const parsedValue = parseInt(valueString, 10);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        setNbScParSemaine(parsedValue.toString());
      }
    }}
    step={1}
    min={0}
  >
    <NumberInputField />
    <NumberInputStepper bg="gray.200">
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
</FormControl>


                </Flex>
                <Flex alignItems="center" mb={4} mt={4}>
  <Text fontSize="xl" mr={4}>Montant total:</Text>
  <Box
    bg="gray.150"
    p={3}
    borderRadius="md"
    boxShadow="0 12px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.15)"
    color="black"
    border="1px solid #ccc"
    w={100}
  >
    <Text fontSize="lg">
      {amountToPay !== null && !isNaN(amountToPayNumber) ? `${amountToPayNumber} DT` : '0 DT'}
    </Text>
  </Box>
</Flex>


                </Box>
                </Box>
              </Stack>
{/************************************  column 2 ******************************************************/}
              <Stack spacing={2} flex={8}>
              <Checkbox isChecked={waiting} onChange={() => setWaiting(!waiting)}>
  <label>
    En liste d'attente
  </label>
</Checkbox>

              <p> Méthode de paiement :</p>
              <RadioGroup value={paymentType} onChange={handlePaymentTypeChange}>
    <HStack spacing="24px">
      <Radio value="CNAM">CNAM</Radio>
      <Radio value="Payout">Paiement</Radio>
      <div>
        <input
          type="file"
          accept=".pdf, image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Button onClick={handleButtonClick}>Upload</Button>
        {upload && <Spinner />}
        {fileLink && <div>Nome de fichier: {fileName}</div>}
      </div>
    </HStack>
  </RadioGroup>

  {isCnamPayment && (
    <Box borderWidth="3px" borderRadius="md" p={4} mb={4}>
      <Flex alignItems="center">
        <Text marginRight="8%">Le patient a apporté sa PEC</Text>
        <Switch isChecked={PECExist} onChange={handleToggle} />
      </Flex>

      <Flex alignItems="center" mt={4}>
        <Box>
          <Text fontSize="lg">Nombre de séances accordé</Text>
          <NumberInput
            value={nbScAccorde}
            bg="white"
            onChange={(valueString) => {
              const parsedValue = parseInt(valueString, 10);
              if (!isNaN(parsedValue) && parsedValue >= 0) {
                setNbScAccorde(parsedValue.toString());
              }
            }}
            step={1}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper bg="gray.200">
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>

        <Box ml={8}>
          <Text fontSize="lg">Accomplement de la séance</Text>
          <NumberInput
            value={moneyadded}
            bg="white"
            onChange={(val) => setmoneyadded(val)}
            step={1}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper bg="gray.200">
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
      </Flex>

      <Flex mt={4}>
        <Box>
          <Text fontSize="lg">Date de début de PEC</Text>
          <Input
            style={{ width: '200px' }}
            type="date"
            value={dateDebut}
            bg="white"
            onChange={(e) => setDateDebut(e.target.value)}
          />
        </Box>
        <Box ml={8}>
          <Text fontSize="lg">Date de fin de PEC</Text>
          <Input
            style={{ width: '200px' }}
            type="date"
            value={dateDeFinPec}
            bg="white"
            onChange={(e) => setdateDeFinPec(e.target.value)}
          />
        </Box>
      </Flex>
    </Box>
  )}

  {isPayout && (
    <Flex alignItems="center" mt={4}>
      <Text fontSize="lg" mr={4}>Prix de la séance</Text>
      <NumberInput
        value={pricePerVisit}
        bg="white"
        onChange={(valueString) => {
          const parsedValue = parseInt(valueString, 10);
          if (!isNaN(parsedValue) && parsedValue >= 0) {
            setpricePerVisit(parsedValue.toString());
          }
        }}
        step={1}
        min={0}
      >
        <NumberInputField bg="white" />
        <NumberInputStepper bg="gray.200">
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text fontSize="lg">DT</Text>
    </Flex>
  )}
    {isPayout && (
          <Flex alignItems="center" mt={4}>
            <Text fontSize="lg" mr={4}>
              Prix de la séance
            </Text>
            <NumberInput
          bg="white"
              value={pricePerVisit}
              onChange={(valueString) => {
                const parsedValue = parseInt(valueString, 10);
                if (!isNaN(parsedValue) && parsedValue >= 0) {
                  setpricePerVisit(parsedValue.toString());
                }
              }}
              step={1}
              min={0}
              mr={2}
              ml={2}
              width={100}
            >
              <NumberInputField  bg="white"  />
              <NumberInputStepper  bg="gray.200" >
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text fontSize="lg">DT</Text>
          </Flex>
        )}



           <Flex >

              <Box display={"flex"}>
                <p>Heure de début</p>
                <Input
                  placeholder="Début"
                  type="time"
                  value={startHour}
                  onChange={(e) => setStartHour(e.target.value)}
                  min="07:00" // Set your desired minimum time
                  max="21:00"
                  bg={"white"}
                />
              </Box>
              <Box display={"flex"} ml={5}>
                <p>Heure de fin</p>
                <Input
                  placeholder="Fin"
                  type="time"
                  bg={"white"}
                  value={endHour}
                  onChange={(e) => setEndHour(e.target.value)}
                  min={startHour}
                  max="21:00"
                />
              </Box>
            </Flex>

                <Box>
                  <HStack spacing={2}>
                      {Object.keys(daysOfWeek).map((day) => (
                      <Checkbox
                        key={day}
                        isChecked={customDays[day]}
                        onChange={() =>
                        {setIsChanged(true)
                          setCustomDays((prevCustomDays) => ({
                            ...prevCustomDays,
                            [day]: !prevCustomDays[day],
                          }))
                        }}
                      >
                        {day}
                      </Checkbox>
                    ))}
                  </HStack>
                </Box>
                {showDateInputs && (
             <Flex >

             <Box display={"flex"}>
               <p>Heure de début</p>
               <Input
                 placeholder="Start Hour"
                 type="time"
                 value={startSamedi}
                 onChange={(e) => setStartSamedi(e.target.value)}
                 min="07:00" // Set your desired minimum time
                 max="21:00"
                 bg={"white"}
               />
             </Box>
             <Box display={"flex"} ml={5}>
               <p>Heure de fin</p>
               <Input
                 placeholder="End Hour"
                 type="time"
                 bg={"white"}
                 value={endSamedi}
                 onChange={(e) => setEndSamedi(e.target.value)}
                 min={startHour}
                 max="13:00"
               />
             </Box>
           </Flex>
          )}
                <Stack spacing={6} flex={1}>
                {Object.values(customDays).some((daySelected) => daySelected) && (
                  <Box>
 <Planning
      planning={planning}
      startHour={startHour}
      endHour={endHour}
      startSamedi={startSamedi}
      endSamedi={endSamedi}
    />                
      </Box>
                )}
              </Stack>
              <div>
      {conflictMessage && (
        <div  style={{
          padding: '10px',
          background: '#ffc107', // Bootstrap warning color
          color: '#000000',
          borderRadius: '4px',
          marginTop: '10px',
          width: '80%',
        }}>
          <p>{conflictMessage}</p>
        </div>
      )}
    </div>
              </Stack>

            </Box>
          </Grid>

        </ModalBody>
        <ModalFooter>
        {isLoading ? (
    <Spinner size="md" color="blue.500" />
  ) : (
    <>

      <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
{data ? "Modifer" : "Ajouter"}      </Button>
      <Button onClick={handleClose}>Annuler</Button>
    </>
  )}

        </ModalFooter>
      </ModalContent>
    </Modal>
  );


};

export default NewPECModal;